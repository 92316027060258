import { compose, mapProps, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import TextAreaInput from '../../../se/components/inputs/TextAreaInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { withDescription } from '../../../se/components/Description';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import { EmailInput } from '../../../se/components/inputs/EmailInput';
import DateInput from '../../../se/components/inputs/DateInput';
import { unparsedAllergiesSummary } from '../../../allergies/AllergiesView';

export const HomePhoneNumberInput = compose(
  withLabel('Patient Home Phone Number'),
  withDescription('To add multiple phone numbers, separate them with a comma.'),
  withProps({ placeholder: 'Enter patient’s home phone number for questionnaire invite' })
)(PhoneInput);

export const MobilePhoneNumberInput = compose(
  withLabel('Patient Mobile Phone Number'),
  withDescription('To add multiple phone numbers, separate them with a comma.'),
  withProps({ placeholder: 'Enter patient’s mobile phone number for questionnaire invite' })
)(PhoneInput);

export const EmailAddressInput = compose(
  withLabel('Patient Email Address'),
  withDescription('To add multiple email addresses, separate them with a comma.'),
  withProps({ placeholder: 'Enter patient’s email address for questionnaire invite' })
)(EmailInput);

export default withProps({
  schema: {
    patientName: compose(withLabel('Patient Name'), withProps({ placeholder: 'e.g., Mark Nordstrom' }))(TextInput),
    patientDateOfBirth: withProps({ required: true, style: { width: '100%' } })(withLabel('Date of Birth')(DateInput)),
    notes: withProps({ rows: 4 })(withLabel('Notes')(TextAreaInput)),
    allergies: compose(
      withLabel('Allergies'),
      mapProps(props => {
        const parsedAllergy = unparsedAllergiesSummary(props.value);
        const isJson = props.rootValue.allergies[0] === '{';
        const value = parsedAllergy ? parsedAllergy : '';
        return {
          ...props,
          isDisabled: isJson && parsedAllergy,
          value: value,
        };
      })
    )(TextAreaInput),
    patientCellPhone: MobilePhoneNumberInput,
    patientHomePhone: HomePhoneNumberInput,
    patientEmailAddress: EmailAddressInput,
  },
})(ObjectInput);
