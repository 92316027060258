import React from 'react';
import Header from './components/Header';
import PatientInfo from './components/PatientInfo';
import styles, { getGridHeaderChildStyles, getGridParentStyles, getGridChildStyles2 } from './styles';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import { useValue } from '../ValueContext';
import MedicationReconciliationField from '../components/MedicationReconciliationField';
import MedicalPassportRadioGroup from '../components/MedicalPassportRadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import { CSSProperties } from 'react';
import get from 'lodash/get';
import Footer from './components/Footer';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicationTextArea from '../components/MedicationTextArea';

const Page3 = () => {
  const { questionnaireAnswers } = useValue();

  // medication
  function checkedCont(value) {
    if (value === 'Continue Med') {
      return true;
    }
    return false;
  }
  function checkedHold(value) {
    if (value === 'Hold medication') {
      return true;
    }
    return false;
  }
  function checkedNew(value) {
    if (value === 'New Prescription given') {
      return true;
    }
    return false;
  }
  function checkedPCP(value) {
    if (value === 'Check with PCP') {
      return true;
    }
    return false;
  }

  function formatDate(inputDate) {
    const day = inputDate.substr(0, 2);
    const month = inputDate.substr(2, 2);
    const year = inputDate.substr(4);

    return `${day}/${month}/${year}`;
  }

  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );
  let nameArr = [medicationList.length];
  let doseArr = [medicationList.length];
  let routeArr = [medicationList.length];
  let freqArr = [medicationList.length];
  let result = [medicationList.length];
  let purposeArr = [medicationList.length];
  let last = [medicationList.length];
  let status = [medicationList.length];

  for (let i = 0; i < medicationList.length; i++) {
    nameArr[i] = get(medicationList[i], 'schema.name.text', '');
    doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
    routeArr[i] = get(medicationList[i], 'schema.route.option', '');
    freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
    result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
    last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

    purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
    status[i] = get(medicationList[i], 'schema.status.option');
  }

  // allergy
  function yesNoAllergies() {
    const allergyQ1 = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[0]', false);
    const allergyQ2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
    const allergyQ3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

    return allergyQ1 || allergyQ2 || allergyQ3;
  }
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }
  let allergySize = 15;

  if (allergyName.length > 15) {
    allergySize = allergyName.length;
  }

  return (
    <div id="page3" className="page" style={styles.root}>
      <Header pageNumber={4} pageCount={11} />
      <div>ALLERGY RECORD</div>
      <PatientInfo />
      <div style={getGridParentStyles(2, true)}>
        <div style={{ ...getGridHeaderChildStyles(2), textAlign: 'left' }}>
          Allergies (Patient Answered{' '}
          <span>
            <input
              type="checkbox"
              name="allergiesYes"
              label="Yes"
              style={{ display: 'inline' }}
              checked={yesNoAllergies()}
            />
          </span>
          )
        </div>
        <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'left' }}>
          Name of Allergy
          {[...Array(allergySize)].map((_, i) => (
            <>
              <div>
                <MedicationReconciliationField
                  name={`allergy.r${i}.c0`}
                  value={i === 0 && allergyName.length === 0 ? 'NO KNOWN DRUG ALLERGIES' : allergyName[i]}
                />
              </div>
            </>
          ))}
        </div>
        <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'left' }}>
          Reaction of sensitivity
          {[...Array(allergySize)].map((_, i) => (
            <>
              <div>
                <MedicationReconciliationField name={`reaction.r${i}.c0`} value={reaction[i]} />
              </div>
            </>
          ))}
        </div>
      </div>
      <div style={{ textAlign: 'left' }}>
        <br />
        Pre-Op Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        Circulating Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        PACU Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
      </div>
    </div>
  );
};

const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '40% 10% 10% 20% 20%' };
const prescriptionTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '50% 50%' };

export default Page3;
